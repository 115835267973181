.App {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: #000;
  text-align: center;
}

.profilePicture {
  width: 320px;
  max-width: 80%;
  height: auto;
  padding: 10px;
  outline: 1px solid #dadada;
}

.intro {
  margin: auto;

  max-width: 80%;
}

.columns {
  display: flex;
}

.columnLeft {
  width: 50%;
  /* background-color: #dadada; */
}

.columnRight {
  width: 50%;
  /* background-color: pink; */
}

.education {
  display: flex;
}

.education div {
  margin-left: 10px;
}
